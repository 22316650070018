import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const Questions: React.FC = () => {
  const classes = useStyles();

  const schemaFaq = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'Are solar panels in Texas worth it?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'Solar energy in TX provides huge benefits to home and business owners. Especially when coupled with a battery storage system, you can save on your energy costs as well protect your home from blackouts. In effect, you can be completely independent of the electric company.',
        },
      },
      {
        '@type': 'Question',
        name: 'How much does it cost to put solar panels on your house in Texas?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'Solar costs less than renting your energy from your utility company forever. With tax credits and an abundance of sun, solar is cash flow positive, meaning it costs you less per month than your current electric bill!',
        },
      },
      {
        '@type': 'Question',
        name: 'Will Texas pay for solar panels?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            "No, there is no such thing as free solar panels and we do not recommend signing up with a third-party owner for a Power Purchase Agreement (PPA) or a solar lease. Third-party owned systems provide little to no value to homeowners long-term and often end up costing homeowners thousands of dollars. However, the federal government offers a 30% tax credit on the installed price of the system (including batteries charged by solar). Buying solar panels is like buying your energy in bulk for the next 25 years, and everyone knows when you buy in bulk you save!. (That's why everyone loves shopping at Costco and Sam's Club.)",
        },
      },
      {
        '@type': 'Question',
        name: 'How do I apply for the solar tax credit in Texas?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'The Federal Tax credit is applied to your federal taxes. This solar tax credit is available for Texas home solar panel systems installed by December 31, 2022. Ask your tax professional how you can best use the 30% Tax Credit.',
        },
      },
      {
        '@type': 'Question',
        name: 'Can you sell electricity back to the grid in Texas?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'Texas has net metering programs, which enable you to receive credits towards your utility bill for any extra power generated by your solar panel system that flows back to the utility grid. Each utility company has different policies regarding these money-saving programs, but generally speaking, you generate more value using the energy yourself versus selling the energy to the utility company. The Database of State Incentives for Renewables & Efficiency (DSIRE) has a great list of Texas utility companies that participate in net metering programs.',
        },
      },
      {
        '@type': 'Question',
        name: "Does solar increase your home's value in Texas?",
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'Yes! Adding solar panels can add significant value to your home. A 2019 Zillow study revealed that homes with solar panels sold at a 4.1% higher price point. National Renewable Energy Laboratory metrics show that homes with solar energy systems installed also sell 20% faster than those without. A US Department of Energy - Berkeley Labs study has shown that buyers are willing to pay a premium of about $15,000 for homes with solar panels.',
        },
      },
      {
        '@type': 'Question',
        name: 'How do I pick a solar company in Texas?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'It pays to do careful research when choosing a solar company in Texas. There are many fly-by-night companies or inexperienced salespeople. Though they may be well-intentioned, they still may steer you in the wrong direction. You have to find a company with a history of helping customers, not just ones that are good at selling. Many companies focus on selling and accumulating sales, rather than customer service. 1) Up to 30% lower up-front cost - Solvana charges less than our competitors because we have almost zero overhead, a digital process to replace salespeople, and no sales commissions. 2) We offer the best equipment - All solar companies offer the same equipment from the same distributors, but we offer them at a better price with the highest installation standards. 3) Expertise from 5 decades of solar industry experience - Solvana has 50 years of experience in construction and custom home improvements. We have been in the solar business since 2008 and have the expert knowledge to help maximize cost savings for you.',
        },
      },
    ],
  };

  return (
    <>
      <Container component="section" maxWidth="lg" className={`${classes.root} ${classes.headerContainer}`}>
        <Typography variant="h2">Questions About Solar Energy in Texas</Typography>
      </Container>
      <Container component="section" maxWidth="lg" className={classes.root}>
        <div className={classes.leftContainer}>
          <Typography variant="h3">Are solar panels in Texas worth it?</Typography>
          <Typography variant="body1">
            Solar energy in TX provides huge benefits to home and business owners. Especially when coupled with a
            battery storage system, you can save on your energy costs as well protect your home from blackouts. In
            effect, you can be completely independent of the electric company.
          </Typography>
          <Typography variant="h3">How much does it cost to put solar panels on your house in Texas?</Typography>
          <Typography variant="body1">
            Solar costs less than renting your energy from your utility company forever. With tax credits and an
            abundance of sun, solar is cash flow positive, meaning it costs you less per month than your current
            electric bill!
          </Typography>
          <Typography variant="h3">Will Texas pay for solar panels?</Typography>
          <Typography variant="body1">
            No, there is no such thing as free solar panels and we do not recommend signing up with a third-party owner
            for a Power Purchase Agreement (PPA) or a solar lease. Third-party owned systems provide little to no value
            to homeowners long-term and often end up costing homeowners thousands of dollars. However, the federal
            government offers a 30% tax credit on the installed price of the system (including batteries charged by
            solar). Buying solar panels is like buying your energy in bulk for the next 25 years, and everyone knows
            when you buy in bulk you save!. (That&#39;s why everyone loves shopping at Costco and Sam&#39;s Club.)
          </Typography>
          <Typography variant="h3">How do I apply for the solar tax credit in Texas?</Typography>
          <Typography variant="body1">
            The Federal Tax credit is applied to your federal taxes. This solar tax credit is available for Texas home
            solar panel systems installed by December 31, 2022. Ask your tax professional how you can best use the 30%
            Tax Credit.
          </Typography>
          <Typography variant="body1">
            You&rsquo;ll need to meet the following criteria to apply for this credit on your Federal taxes:
          </Typography>
          <Typography component="span" variant="body1">
            <ul>
              <li>
                You have installed a home solar energy system at any point from 2006 through the end of 2022, even if
                it&#39;s not your primary residence
              </li>
              <li>
                The installation took place on a solar panel system that you own outright, either through cash purchase
                or a solar loan
              </li>
            </ul>
          </Typography>
        </div>
        <div className={classes.rightContainer}>
          <Typography variant="h3">Can you sell electricity back to the grid in Texas?</Typography>
          <Typography variant="body1">
            Texas has net metering programs, which enable you to receive credits towards your utility bill for any extra
            power generated by your solar panel system that flows back to the utility grid. Each utility company has
            different policies regarding these money-saving programs, but generally speaking, you generate more value
            using the energy yourself versus selling the energy to the utility company. The&nbsp;
            <a href="https://programs.dsireusa.org/system/program/tx/solar">
              Database of State Incentives for Renewables &amp; Efficiency
            </a>
            &nbsp; (DSIRE) has a great list of Texas utility companies that participate in net metering programs.
          </Typography>
          <Typography variant="h3">Does solar increase your home&#39;s value in Texas?</Typography>
          <Typography variant="body1">
            Yes! Adding solar panels can add significant value to your home. A 2019 Zillow study revealed that homes
            with solar panels&nbsp;
            <a href="https://www.zillow.com/research/solar-panels-house-sell-more-23798/">
              sold at a 4.1% higher price point.
            </a>
            &nbsp;National Renewable Energy Laboratory metrics show that homes with solar energy systems installed
            also&nbsp;
            <a href="https://www.nrel.gov/docs/fy07osti/38304-01.pdf">sell 20% faster</a>
            &nbsp;than those without. A US Department of Energy - Berkeley Labs study has shown that buyers are willing
            to pay a&nbsp;
            <a href="https://newscenter.lbl.gov/2015/01/13/berkeley-lab-illuminates-price-premiums-u-s-solar-home-sales/">
              premium of about $15,000
            </a>
            &nbsp;for homes with solar panels.
          </Typography>
          <Typography variant="h3">How do I pick a solar company in Texas?</Typography>
          <Typography variant="body1">
            It pays to do careful research when choosing a solar company in Texas. There are many fly-by-night companies
            or inexperienced salespeople. Though they may be well-intentioned, they still may steer you in the wrong
            direction. You have to find a company with a history of helping customers, not just ones that are good at
            selling. Many companies focus on selling and accumulating sales, rather than customer service.
          </Typography>
          <Typography variant="body1">
            1) Up to 30% lower up-front cost &mdash; Solvana charges less than our competitors because we have almost
            zero overhead, a digital process to replace salespeople, and no sales commissions.
          </Typography>
          <Typography variant="body1">
            2) We offer the best equipment &mdash; All solar companies offer the same equipment from the same
            distributors, but we offer them at a better price with the highest installation standards.
          </Typography>
          <Typography variant="body1">
            3) Expertise from 5 decades of solar industry experience &mdash; Solvana has 50 years of experience in
            construction and custom home improvements. We have been in the solar business since 2008 and have the expert
            knowledge to help maximize cost savings for you.
          </Typography>
        </div>
        <script type="application/ld+json">{JSON.stringify(schemaFaq)}</script>
      </Container>
    </>
  );
};

export default Questions;
