import React from 'react';
import { Container, List, ListItem, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Routes } from 'utils/Routes';
import InstallSolarPanels from '../InstallSolarPanels';
import StartSavingWithSolar from '../StartSavingWithSolar';
import SolarIncentivesAndTaxCredits from '../SolarIncentivesAndTaxCredits';
import SolarPanelLaws from '../SolarPanelLaws';
import Questions from '../Questions';

const Content: React.FC = () => {
  const classes = useStyles();

  return (
    <Container component="section" maxWidth="lg" className={classes.root}>
      <div className={classes.copyContainer}>
        <InstallSolarPanels />
        <StartSavingWithSolar />
        <SolarIncentivesAndTaxCredits />
        <SolarPanelLaws />
        <Questions />
      </div>
    </Container>
  );
};

export default Content;
