import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { useStyles } from './styles';

const StartSavingWithSolar: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "tx-image-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "tx-image-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "tx-image-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Container component="section" maxWidth="lg" className={`${classes.root} ${classes.headerContainer}`}>
        <Typography variant="h2">Start Saving with Solar in the Lone Star State</Typography>
      </Container>
      <Container component="section" maxWidth="lg" className={classes.root}>
        <div className={classes.leftContainer}>
          <Typography variant="body1">
            When building a system, one of the most significant factors to consider is the cost of solar panels in
            Texas. Solar panels typically cost between $15,000 and $25,000 before tax credits or rebates are applied,
            which reduce the overall cost.
          </Typography>
          <Typography variant="body1">
            <Img Tag="span" fluid={data.image.childImageSharp.fluid} />
          </Typography>
          <Typography variant="body1">
            A solar system&#39;s cost is determined by a number of factors, including size, layout, installation, and
            local expenditures like permitting fees. Texas solar panels are typically priced in dollars per watt, with
            the average home solar system costing between $3 and $5 per watt.
          </Typography>
          <Typography variant="body1">
            <Img Tag="span" fluid={data.image2.childImageSharp.fluid} />
            <Typography variant="caption">
              <a href="https://www.energysage.com/local-data/solar-panel-cost/tx/">
                Source: https://www.energysage.com/local-data/solar-panel-cost/tx/
              </a>
            </Typography>
          </Typography>
          <Typography variant="body1">
            The initial cost of purchasing solar panels in Texas is considerable; however, this cost has decreased in
            recent years. Solar panel prices have&nbsp;
            <a href="https://www.saveonenergy.com/solar-energy/solar-panel-cost/">dropped by more than 70%</a>
            &nbsp;in the last decade. If the upfront solar panel cost in Texas is prohibitively expensive, homeowners
            can choose from various finance alternatives or solar leasing.
          </Typography>
        </div>
        <div className={classes.rightContainer}>
          <Typography variant="body1">
            Many prospective solar power consumers wonder how long it will take to return their original solar panel
            investment. The payback period is the time it takes for solar panel owners to return their initial
            investment through solar power savings. In Texas, the median solar energy payback period is 11.80 years.
          </Typography>
          <Typography variant="body1">
            <Img Tag="span" fluid={data.image3.childImageSharp.fluid} />
          </Typography>
          <Typography variant="h3">Solar Power Savings Over 25 years</Typography>
          <Typography variant="body1">
            A 5kW system will generate around 6,750 kWh per year. The cost of electricity varies by state, but in Texas,
            you&#39;ll spend approximately 11.86 cents per kilowatt-hour (kWh). We just multiply your solar panel kWh
            production by the rate you will no longer pay the electricity company:
          </Typography>
          <Typography variant="body1">6,750 kWh x $11.86/kWh = $800.55 Value of Solar (Annual Savings)</Typography>
          <Typography variant="body1">
            Even if we assume rates won&rsquo;t go up, you can expect solar panels&#39; to save you thousands over their
            25-year lifespan:
          </Typography>
          <Typography variant="body1">
            $800.55/year * 25 years = $20,013.75 savings over the warrantied lifetime
          </Typography>
          <Typography variant="body1">
            Before we even consider the tax breaks and property value increases, there is an $11,921.75 return on
            investment.
          </Typography>
          <Typography variant="body1">
            With an annual cost savings of $800.55, you&#39;ll be able to pay off your solar panels in about ten years!
          </Typography>
        </div>
      </Container>
    </>
  );
};

export default StartSavingWithSolar;
