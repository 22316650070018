import React from 'react';
import Layout from '../containers/Layout';
import TexasSolarPanelInstallation from '../containers/TexasSolarPanelInstallation';

const TexasSolarPanelInstallationPage: React.FC = () => (
  <Layout
    title="Texas Solar Panel Installation | Texas Solar Panel Company | Solvana"
    desc="Are you taking advantage of the blazing sun in Texas? Texas solar panel installation is rising as more and more people want to lower their energy bills."
  >
    <TexasSolarPanelInstallation />
  </Layout>
);

export default TexasSolarPanelInstallationPage;
