import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { useStyles } from './styles';

const SolarIncentivesAndTaxCredits: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "tx-image-7.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "tx-image-8.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "tx-image-9.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Container component="section" maxWidth="lg" className={`${classes.root} ${classes.headerContainer}`}>
        <Typography variant="h2">Texas Solar Incentives and Tax Credits</Typography>
      </Container>
      <Container component="section" maxWidth="lg" className={classes.root}>
        <div className={classes.leftContainer}>
          <Typography variant="body1">
            <Img Tag="span" fluid={data.image.childImageSharp.fluid} />
            <Typography variant="caption">
              <a href="https://unsplash.com/s/photos/energy">Source: Unsplash</a>
            </Typography>
          </Typography>
          <Typography variant="body1">
            The federal government and several state governments have initiatives to encourage green energy investments
            such as solar panels.
          </Typography>
          <Typography variant="body1">
            For starters, homeowners who install solar panels on their primary or secondary homes can receive a federal
            tax credit of 30% for systems constructed in 2021 and 2022. Because there is no restriction on how much you
            can claim, the larger your PV solar system, the more you may claim as your federal tax credit. Remember that
            this tax credit only applies if you bought or financed your solar system. It is not applicable to solar
            leases or solar PPAs.
          </Typography>
          <Typography variant="body1">
            There are also Texas solar subsidies available to households and businesses to help them save money on solar
            panels. Texas citizens who purchase a solar system are excluded from any increases in property value.
            Several utilities and municipal governments have also introduced their own incentives
          </Typography>
          <Typography variant="h3">Solar Rebate Schemes in Texas</Typography>
          <Typography variant="body1">
            <Img Tag="span" fluid={data.image2.childImageSharp.fluid} />
            <Typography variant="caption">
              <a href="https://unsplash.com/photos/hxUcl0nUsIY">Source: Unsplash</a>
            </Typography>
          </Typography>
          <Typography variant="body1">
            While Texas lacks a national rebate scheme, many local governments and utilities provide incentives to
            households who wish to transition to solar. These include:
          </Typography>
          <Typography component="div" variant="body1">
            <ul>
              <li>
                <a href="https://www.gvec.org/home-solutions-2/solar/">Guadalupe Valley Electric Cooperative</a>
                &nbsp; and the City of Sunset Valley both provide refunds to residents in their respective districts.
              </li>
              <li>
                <a href="https://www.cpsenergy.com/en/my-home/savenow/rebates-rebate/solar-photovoltaic-rebate.html">
                  CPS Energy
                </a>
                :&nbsp;If you are a CPS Energy customer, you may be eligible for a $2,500 rebate for installing solar
                panels on the roof of your home. You can also get an additional $500 if you install locally sourced
                solar panels.
              </li>
              <li>
                <b>Oncor Electric Delivery</b>&mdash; Oncor, one of Texas&#39; top power providers, also provides
                considerable refunds based on the size of a home&#39;s solar system and the expected reduction in energy
                use. The average refund is around $5,400, with a maximum of $8,500.
              </li>
              <li>
                <a href="https://austinenergy.com/ae/green-power/solar-solutions/for-your-home/solar-photovoltaic-rebates-incentives">
                  Austin Energy
                </a>
                : If you reside in Austin, you may receive a $2,500 reimbursement. You&#39;re also qualified for Austin
                Energy&#39;s Value of Solar Tariff, which rewards you $0.097 per kilowatt-hour (kWh) produced by your
                solar panels
              </li>
            </ul>
          </Typography>
        </div>
        <div className={classes.rightContainer}>
          <Typography variant="body1">
            As you can see, Texas utility providers offer a plethora of incentives to assist you to offset the cost of
            putting solar on your house.
          </Typography>
          <br />
          <Typography variant="h3">Property Tax Exemption for Renewable Energy Systems</Typography>
          <Typography variant="body1">
            Aside from lowering your power costs, solar offers another financial advantage: it may raise the worth of
            your property. While greater house prices usually mean elevated real estate taxes, the state of Texas offers
            a renewable energy property tax exemption,&nbsp;that the&nbsp;inhabitants don&#39;t have to pay additional
            taxes because of the worth that their PV&nbsp;panels produce.
          </Typography>
          <br />
          <br />
          <Typography variant="caption">
            <Img Tag="span" fluid={data.image3.childImageSharp.fluid} />
            <a href="https://unsplash.com/photos/k8HniqcdYS4">Source: Unsplash</a>
            <br />
          </Typography>
          <br />
          <Typography variant="h3">Texas net metering</Typography>
          <Typography variant="body1">
            While Texas doesn&#39;t have a nationwide net metering regulation, several utilities in the state have
            policies that reward solar energy system owners for the power produced by their panels.
          </Typography>
          <Typography variant="body1">
            If your provider has a net metering program, any extra electricity produced by your solar panels will be
            credited to your electric bill. When your solar panels aren&#39;t generating enough power to satisfy your
            demands (for example, at night or when it&#39;s cloudy outside), you may use your Texas net metering reward
            points instead of purchasing electricity from the utility.
          </Typography>
          <br />
          <Typography variant="h3">The Federal Solar Tax Credit</Typography>
          <Typography variant="body1">
            The&nbsp;
            <a href="https://news.energysage.com/solar-tax-credit-explained/">Investment Tax Credit (ITC)</a>
            &nbsp;can cut the price of your solar energy installation by 30%. Remember the ITC only applies to
            individuals who acquire their PV solar system fully (either with a solar loan or cash) and that you must
            have a sufficient income for the tax benefit to be substantial.
          </Typography>
        </div>
      </Container>
    </>
  );
};

export default SolarIncentivesAndTaxCredits;
