import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import { useStyles } from './styles';
import { Routes } from '../../../utils/Routes';

const InstallSolarPanels: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "tx-image-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "tx-image-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Container component="section" maxWidth="lg" className={`${classes.root} ${classes.headerContainer}`}>
        <Typography variant="h2">How We Install Solar Panels in Texas</Typography>
      </Container>
      <Container component="section" maxWidth="lg" className={classes.root}>
        <div className={classes.leftContainer}>
          <Typography variant="body1">
            We are currently aiding Texas households and business owners to switch to solar energy in&nbsp;
            <Link to={Routes.HowItWorks}>three simple steps</Link>
            &nbsp;&ndash; at their own pace and convenience.
            <br />
            <br />
            Step 1: Sign up for your free solar energy proposal.
            <br />
            We collect satellite data and photographs and utilize them with our professional analytic approach &ndash;
            which has been in use for over a decade &ndash; to provide the best solutions depending on your roof and
            electricity usage.
          </Typography>

          <Typography variant="body1">
            Step 2: Evaluate and choose the best solar energy option for you.
            <br />
            If solar is the right fit, your solar advocate will call you within 48 hours of your purchase to begin the
            installation process.
          </Typography>
          <Typography variant="body1">
            <Img Tag="span" fluid={data.image.childImageSharp.fluid} />
            <Typography variant="caption">
              <a href="https://unsplash.com/photos/2pbElAv6K4I">Source: Unsplash</a>
            </Typography>
          </Typography>
          <Typography variant="body1">
            Step 3: Install and configure the solar panels and batteries.
            <br />
            We&#39;ll handle the rest after you&#39;ve decided on the best solar system for your home or business and
            completed your purchase through our secure online portal. To make this process as simple as feasible for
            you, our experts will handle negotiations with your utility company, permitting, installation, inspections,
            and even warranty claims.
          </Typography>
          <Typography variant="body1">
            You can utilize a smart home battery for solar energy storage to store extra energy generated by your solar
            panel array for usage when you need it the most. This means more savings, lower carbon emissions, more
            energy independence, and more control over your sustainable solar energy. It also means that when the sun
            goes down, the clouds blow in, or the lights in your neighborhood go out, you can rely on clean solar energy
            to meet your requirements.
          </Typography>
        </div>
        <div className={classes.rightContainer}>
          <Typography variant="body1">
            At Solvana, we are solar panel installers in Texas as well as battery experts. We design systems that will
            power you through storms, blackouts, and the night. We rely on best-in-class technology, as well as BESS to
            meet our clients&#39; home battery needs across Texas, Florida, Massachusetts, and New Jersey. We&rsquo;ll
            propose the best home battery option for your house based on your specific energy demands, storage unit
            availability, pricing, and preferences.
          </Typography>
          <Typography variant="body1">
            Our innovative pergolas offer an excellent balance of function and cost. They&rsquo;re frequently used in
            conjunction with a full-roof solar system.
          </Typography>
          <Typography variant="body1">
            <Img Tag="span" fluid={data.image2.childImageSharp.fluid} />
          </Typography>
          <Typography variant="body1">
            We prefer to situate these pergolas away from shade trees so they create more solar energy. Still, when it
            comes to outdoor recreational places, aesthetics is sometimes more essential than production.
          </Typography>
          <Typography variant="body1">
            We also hide conduit, so it goes through the attic when possible, making your array look sleek and
            professional. Many other companies don&#39;t do that.
          </Typography>
          <Typography variant="body1">
            Are you ready to go solar in Texas? Click&nbsp;
            <Link to={Routes.ContactUs}>here</Link>
            &nbsp;to get started today.
          </Typography>
        </div>
      </Container>
    </>
  );
};

export default InstallSolarPanels;
