import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { useStyles } from './styles';

const SolarPanelLaws: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "tx-image-10.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Container component="section" maxWidth="lg" className={`${classes.root} ${classes.headerContainer}`}>
        <Typography variant="h2">Texas Solar Panel Laws</Typography>
      </Container>
      <Container component="section" maxWidth="lg" className={classes.root}>
        <div className={classes.leftContainer}>
          <Typography variant="body1">
            <Img Tag="span" fluid={data.image.childImageSharp.fluid} />
            <Typography variant="caption">
              <a href="https://unsplash.com/photos/6sl88x150Xs">Source: Unsplash</a>
            </Typography>
          </Typography>
          <Typography variant="body1">
            Texas, for the most part, is a solar-power-friendly state. However, there existed a legal gap that permitted
            property developers to prevent the installation of solar panels on homes in new areas until 2015. This
            loophole has subsequently been fixed, and households in Texas can now install solar panels.
          </Typography>
          <br />
          <Typography variant="h3">Texas Homeowners</Typography>
          <Typography variant="body1">
            <a href="http://www.legis.state.tx.us/tlodocs/82R/billtext/html/HB00362F.HTM">Texas House Bill (HB)362</a>
            &nbsp;was initially meant to prohibit Homeowners&rsquo; Associations and Property Owners Associations from
            outright prohibiting the installation of solar panels, but it required homeowners to submit a formal request
            for planning clearance to an Architecture Review Committee.
          </Typography>
          <Typography variant="body1">
            According to&nbsp;
            <a href="http://www.statutes.legis.state.tx.us/Docs/PR/htm/PR.202.htm%23202.010">
              Texas Property Code Section 202.010
            </a>
            , HOAs and POAs may reject approval to install solar panels in very restricted circumstances, such as:
          </Typography>
        </div>
        <div className={classes.rightContainer}>
          <Typography component="div" variant="body1">
            <ul>
              <li>If the installation is carried out in a manner that would void the guarantee.</li>
              <li>If the installation would take place on a shared site in the development.</li>
              <li>
                If the panels are longer than the roofline, are not parallel to the roof, or do not follow the roofline.
              </li>
              <li>Any panel element that is not black, bronze, or silver in color.</li>
              <li>The panels were put without the HOA/consent.</li>
              <li>If the gadgets are illegal or pose a risk to public safety.</li>
              <li>If the panels are put on the ground and extend above/beyond the owner&#39;s property fence.</li>
            </ul>
          </Typography>
          <Typography variant="body1">
            POA&#39;s Senate Bill 1626 forbids homeowners from installing solar panels during the construction phase of
            a property complex/housing area. Some property developers exploited this regulation by keeping their homes
            &quot;under construction&quot; for decades, allowing them to reject permission for homeowners to install
            solar panels.
          </Typography>
          <Typography variant="body1">
            This gap was resolved in 2015 when Bill 1626 was amended to indicate that only property developments with
            fewer than 50 units might reject authorization for homeowners to install solar panels during the development
            phase. Larger property projects cannot deny authorization for solar panels.
          </Typography>
        </div>
      </Container>
    </>
  );
};

export default SolarPanelLaws;
