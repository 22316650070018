import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { Routes } from '../../../utils/Routes';

const Hero: React.FC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "tx-image-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container maxWidth="lg" className={classes.root}>
      <div className={classes.leftContainer}>
        <div className={classes.innerContainer}>
          <Typography color="primary" variant="h1">
            Texas
            <br />
            Solar Panel Installation
          </Typography>
          <Typography variant="body1">
            Everything is bigger in Texas, and the advantages of solar power are no exception. With an annual average
            of&nbsp;
            <a href="https://www.currentresults.com/Weather/Texas/annual-days-of-sunshine.php">
              236.8 days of sunshine
            </a>
            &nbsp;and lower-than-average solar costs, it&#39;s no surprise that Texas is ranked as a top 10 state for
            solar by the&nbsp;
            <a href="https://www.seia.org/state-solar-policy/texas-solar">Solar Energy Industries Association (SEIA)</a>
            . Going solar is easier than ever, owing to several substantial solar incentives and rebates.
          </Typography>
          <Typography variant="body1">
            Texas is one of the nation&#39;s leaders in solar power generation, thanks in part to the declining cost of
            solar panels in Texas and the increasing number of individuals who opt to install residential solar systems
            for their homes.
          </Typography>
          <Typography variant="body1">
            Texas is the second-most populated state in the US, with a population of 29 million people. Texas solar
            projects now provide enough energy to power over 935,000 households.
          </Typography>
          <Typography variant="body1">
            Texas consumers who acquire a solar system for their house might save more than $960 per year in energy
            expenditures based on the standard average Texas power rate and bill. Prices have dropped by more than 70%
            in the last decade, making PV system installation more affordable. And with the addition of a Battery
            system, Solar Panels can power homes during blackouts making Texans even more independent.
          </Typography>
          <Typography variant="body1">The big question is &ndash; is your home solar-worthy?</Typography>
          <Typography variant="body1">
            Let our experts tell you if solar makes sense for your home. We take into account over 50 factors when
            considering solar worthiness for homes. If solar doesn&#39;t make sense, we&rsquo;ll let you know. It&#39;s
            all digital and free of charge. Simply&nbsp;
            <Link to={Routes.ContactUs}>contact us</Link>, and we&rsquo;ll send you your solar layout for your review.
          </Typography>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <Img fluid={data.image.childImageSharp.fluid} />
      </div>
    </Container>
  );
};

export default Hero;
